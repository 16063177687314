<template>
  <div>
    <component :is="selectedComponent" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    Request: () => import('./Request.vue'),
    Requested: () => import('./Requested.vue'),
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['myRequests']),
    isMyRequestExisted() {
      return this.myRequests.length > 0
    },
    selectedComponent() {
      if (this.isMyRequestExisted) {
        return 'requested'
      }
      return 'request'
    },
  },
  created() {
    this.getMyRequests()
  },
  methods: {
    ...mapActions(['getMyRequests']),
  },
}
</script>
